import {React} from 'react';
import MainGrid from '../components/MainGrid';
import * as screens from '../components/screens'

export const titles = ['sial', 'first', 'zarnitsa', 'real', 'anyway']
const screenshots = titles?.map(t => screens[t][0])
const today = new Date()
const premiere = new Date(1666328400248)
const sial_cover = 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers_1500/1500_sail.jpg'

const covers = [ sial_cover,
                'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/selected/website1.jpg',
                'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers/zarnitsa.jpg',
                'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers/real.jpg',
                'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers/anyway.jpg'
                ]
function Narrative() {
    return(
        <MainGrid titles={titles} screens={screenshots} section="films" covers={covers}/>
    )
}
export default Narrative