import React, { useState } from 'react';
import {Link} from 'react-router-dom'

function TopNav() {
    const [active, setActive] = useState()

    
    return (
        <nav className='main-nav'>
            <Link onClick={() => setActive(undefined)} to={'/'}><p className='brand'>Alena Shevchenko</p>
                 <p className='br-title'>director & writer</p>
            </Link>
            <ul className='menu'>
            {/* <li> <Link to={'/'}> SELECTED </Link> </li> */}
            <li onClick={() => setActive(0)} className={`un ${active === 0?  'active' : ''}`}><Link to={'/portfolio/films'}>FILMS </Link> </li>
            <li onClick={() => setActive(1)} className={`un ${active ==1?  'active' : ''}`}><Link to={'/portfolio/commercial'}>COMMERCIAL </Link> </li>
            <li onClick={() => setActive(2)} className={`un ${active ==2 ?  'active' : ''}`}><Link to={'/portfolio/music-videos'}>MUSIC VIDEO </Link> </li>
            <li onClick={() => setActive(3)} className={`un ${active == 3?  'active' : ''}`}><Link to={'/about'}>ABOUT </Link> </li>
            </ul>
        </nav>
    );
  }
  
  export default TopNav; 