import React from 'react';
import { useEffect, useState } from 'react';



function ScreenshotGrid(props) {
    const [sortedStills, setSortedStills] = useState([])
    
    useEffect(() => {
      const stills = props.largeStills
      if (!stills) return
      stills.sort(function(a, b) {
        let aNumber = Number(a.split('_').pop().split('.')[0]);
        let bNumber = Number(b.split('_').pop().split('.')[0]);
    
        return aNumber - bNumber;
      });
      setSortedStills(stills)
    }, [props.largeStills]);


    const amt = props['largeStills']?.length
    const width = window.innerWidth
    const gridlen = width < 768? 2 : !props.largeGrid? 3 : 2

    
    const remainder = amt%gridlen
    // console.log(remainder, amt, gridlen)
    return (
       <div className={`grid-container${remainder? ` ss-remainder-${remainder}` : ''} 
       ${(!props.largeGrid) ? '' : 'con-2'}`}> 

        {sortedStills?.map(
            (ss,i)  =>{
              return(
                <div className='sc-placeholder'>
                  <picture> 
                    <source srcSet={ss} media="(min-width: 900px)"/> 
                    <img className='preview-ss' alt={`screenshot ${i}`} src={ss?.replaceAll(1500,1000)}
                     loading={i>4 ? 'lazy': null} decoding="async"/>
                  </picture>
                </div>
              )
            })}  
       </div>
    );
  }
  
  export default ScreenshotGrid;