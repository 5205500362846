
import React, { useEffect } from 'react';
import {Link, useParams} from 'react-router-dom'


const names = {'first': "I'll say it first", 'real': "What's real", 'sial': ' ', 'limeMen': 'Lime Men'}
const beregovoy = ['blowup', 'portrait', 'porter']

const findName = (title) => {
    if (names[title]) {
        return names[title]
    }
    if (beregovoy.indexOf(title) != -1) {
        return 'beregovoy: ' + title
    } 
  
    return title
}

function MainGrid(props) {
    const params = useParams()
    const section = props.section? props.section: params.type
    const {screens, titles, covers, screensOptimized} = props
    const remainder = titles?.length % 2
    const calc = (i, section=false) => {
        // if (section == 'selected' && i>=3) {
        //     return i+2}
        // else {
            return i+1
        // }
    }



    useEffect(() => {
        
        document.title = props.section != 'selected' ? props.section[0]?.toUpperCase() + props.section.substring(1) : 'Alena Shevchenko'
      }, [props.section]);
      useEffect(() => { 
        
        document.description = props.section != 'selected' ?
         props.section[0]?.toUpperCase() + props.section.substring(1)
          : 'Alena Shevchenko'
      }, [props.section]);

    return(
     <main> 
      
        <section className={`maingrid remainder-${remainder && section != 'music-videos' ? remainder : ''}`}>
            {screens?.map((img, i) => 
                <Link to={`/portfolio/${section}/${calc(i, section)}`}>
                    <div className='grid-element'> 
                        <div className='preview-pic'>
                            <picture> 
                              {screensOptimized?.length?
                                <source srcSet={covers && covers[i]? covers[i] : screensOptimized[i]} media="(min-width: 900px)"/> : ''}
                                <source srcSet={covers && covers[i]? covers[i] : img} media="(min-width: 900px)"/> 
                               
                                <img priority={i<4? 'high' : 'low'} 
                                    src={covers && covers[i]? covers[i] : 
                                        screensOptimized?.length? screensOptimized[i]?.replaceAll(1500,1000) :
                                        img?.replaceAll(1500,1000)} alt={titles[i]} 
                                    loading={i>4 ? 'lazy': null} decoding="async"></img>
                            </picture>
                    
                        </div>
                        <div className='portfolio-text'>
                            <h3 className={titles[i]?.length<13? 'normal-title': 'long-title'}>
                                {findName(titles[i])}
                            </h3>
                        </div>
                    </div>
                </Link>
            )}
        </section>
    </main>
        
    )
}
export default MainGrid