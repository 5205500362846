import {React, useEffect} from 'react';


function About() {
    useEffect(() => {
        document.title = 'About Alena Shevchenko'
      }, []);


    return(
        <>
        <div style={{backgroundColor: 'white'}} className='contact-container'> 
            <div className='space'>
                <img 
                // style={{width: '25vw', objectFit: 'cover', minHeight: '40vh'}}
                src='https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/about_1000/1000_IMG_6774+(5).JPG'/>
                <div>

                <div className='icons' id="iq">
                <a href='https://www.instagram.com/alenshev/'> 
                        <svg 
                        role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Instagram</title><path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/></svg>
                    </a>
                <a href='https://vimeo.com/alenshev'> 
                    <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Vimeo</title>
                    <path d="M23.9765 6.4168c-.105 2.338-1.739 5.5429-4.894 9.6088-3.2679 4.247-6.0258 6.3699-8.2898 6.3699-1.409 0-2.578-1.294-3.553-3.881l-1.9179-7.1138c-.719-2.584-1.488-3.878-2.312-3.878-.179 0-.806.378-1.8809 1.132l-1.129-1.457a315.06 315.06 0 003.501-3.1279c1.579-1.368 2.765-2.085 3.5539-2.159 1.867-.18 3.016 1.1 3.447 3.838.465 2.953.789 4.789.971 5.5069.5389 2.45 1.1309 3.674 1.7759 3.674.502 0 1.256-.796 2.265-2.385 1.004-1.589 1.54-2.797 1.612-3.628.144-1.371-.395-2.061-1.614-2.061-.574 0-1.167.121-1.777.391 1.186-3.8679 3.434-5.7568 6.7619-5.6368 2.4729.06 3.6279 1.664 3.4929 4.7969z"/></svg>
                </a>
                <a href='mailto: alnshevchenko@gmail.com' style={{marginLeft: 0}}>
                   <svg  viewBox="0 0 64 64">
                    <path id="Mail" d="M58.0034485,8H5.9965506c-3.3136795,0-5.9999995,2.6862001-5.9999995,6v36c0,3.3137016,2.6863203,6,5.9999995,6h52.006897c3.3137016,0,6-2.6862984,6-6V14C64.0034485,10.6862001,61.3171501,8,58.0034485,8z M62.0034485,49.1108017L43.084549,30.1919994l18.9188995-12.0555992V49.1108017z M5.9965506,10h52.006897c2.2056007,0,4,1.7943001,4,4v1.7664003L34.4677505,33.3134003c-1.4902,0.9492989-3.3935013,0.9199982-4.8495998-0.0703011L1.9965508,14.4694996V14C1.9965508,11.7943001,3.7910507,10,5.9965506,10z M1.9965508,16.8852005L21.182251,29.9251003L1.9965508,49.1108017V16.8852005zM58.0034485,54H5.9965506c-1.6473999,0-3.0638998-1.0021019-3.6760998-2.4278984l20.5199013-20.5200024l5.6547985,3.843401c1.0859013,0.7383003,2.3418007,1.1083984,3.5995998,1.1083984c1.1953011,0,2.3925018-0.3339996,3.4463005-1.0048981l5.8423996-3.7230015l20.2961006,20.2961025C61.0673485,52.9978981,59.6508713,54,58.0034485,54z"/></svg>
                </a>
                    </div>
          </div>
         
        </div>
        <article>
                <p className='art-info'> 
                Alena is a Berlin based writer and director. 
                She balances between commercial, films and music videos.
                <br/><br/>
                She likes to tell stories through a female perspective. 
                Her focus is relationships, sexuality and comedy.  
                In her work, she marries three of her biggest passions — one is a big love of telling stories through characters, the other one - dance and fashion. And on top of that she adds a piece of irony.
                <br/><br/>
               
                Her films have been selected for and won some of the world’s major fashion and short film festivals including YDA, The Palm Springs International ShortFest, Aesthetica Film Festival, Shanghai International Film Festival and Berlin Commercial.
                <br/><br/>

                Alena worked with many wonderful clients including Bumble, Womanizer, Nike, Adidas, L’oreal, Samsung, LG, Schwarzkopf, Honor.
                <br/><br/>
                Her work has been published by Nowness, Booom TV and  Director’s notes.
                <br/><br/>
                Today Alena is developing a script for a feature film.
                {/* <br/><br/> */}
                {/* <b> Style </b>
                <br/>
                In my work, I marry three of my biggest passions — one is big love of telling stories through characters, and the others are dance and fashion.  And on top of that I add a piece of irony. 
                <br/><br/>
                <b> Clients </b>
               <br/>
                Nike,  Adidas, L’oreal, Samsung, LG, Schwarzkopf, Honor, Deiсhmann.
                <br/><br/>

                <b>Read an article on</b>
                <br/>
                Director’s Note:
                <a style={{textDecoration: 'underline'}} href='https://directorsnotes.com/2020/02/06/alena-shevchenko-ill-say-it-first/'> Alena Shevchenko Hesitates to Make the First Move in Playful Will She Won’t She Short ‘I’ll Say It First’
                </a> */}
                </p>

            </article>
      </div>

      {/* <div className='c-info'>
           
        <p> <a href="tel:+49 176 65747615">+49 176 65747615</a></p> 
        <p> <a href = "mailto: alnshevchenko@gmail.com">alnshevchenko@gmail.com</a></p>
        <p >Berlin, Germany</p>
     </div> */}
      </>

    )
}

export default About