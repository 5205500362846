import './noncritical.css';
import TopNav from './components/TopNav';
import MobileMenu from './components/MobileMenu';
import {Link} from 'react-router-dom'
import React, { useState } from 'react';

function App() {

  const [sidebar, setSidebar] = useState(false)
  const showSidebar = () => setSidebar(!sidebar)
  const [active, setActive] = useState()
  const onCl = (ind) => {
      showSidebar();
      setActive(ind)
  }
  const homeClick = () => {
    setActive(undefined);
    setSidebar(false)
  }

  return (
    <React.Fragment> 
      <header>
        {!sidebar? <TopNav/> : ''}
        <div className='mobile-header'> 
          <div className='j-between'> 
          <Link style={{marginBottom: '1rem'}} 
          onClick={() => homeClick()} to={'/'}><p className='brand-mobile'>Alena Shevchenko</p>
         <p className='br-title br-mobile'>director & writer</p>
          </Link>
            <svg className="menu-sandwich" onClick={showSidebar} height="384pt" viewBox="0 -53 384 384" width="384pt" xmlns="http://www.w3.org/2000/svg"><path d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/><path d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/><path d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/>
            </svg>
          </div>
        </div>
      </header>
      <MobileMenu sidebar={sidebar} active={active} onCl={onCl}/>
    </React.Fragment>
  
  );
}

export default App;
