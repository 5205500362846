import './MobileMenu.css'
import React, { useState } from 'react';
import {Link} from 'react-router-dom'


function MobileMenu(props) {

    return(
        <nav className={props.sidebar? "show-sidebar" : "sidebar-off"}>
        {/* <svg onClick={props.showSidebar} classname="close-menu" fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px"><path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"/></svg> */}
        <ul className="sidebar">
            <li className={`un ${props.active === 0?  'active' : ''}`} onClick={() => props.onCl(0)}>
                <Link to={'/portfolio/films'}>FILMS </Link> </li>
            <li className={`un ${props.active === 1?  'active' : ''}`} onClick={() => props.onCl(1)}>
                <Link to={'/portfolio/commercial'}>COMMERCIAL </Link> </li>
            <li className={`un ${props.active === 2?  'active' : ''}`} onClick={() => props.onCl(2)}>
                <Link to={'/portfolio/music-videos'}>MUSIC VIDEO </Link> </li>
            <li className={`un ${props.active === 3?  'active' : ''}`} onClick={() => props.onCl(3)}>
                <Link to={'/about/'}>ABOUT </Link> </li>
        </ul>
    </nav>
    )
}
export default MobileMenu