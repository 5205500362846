const data = [{
    "code": "zarnitsa",
    "title": "Zarnitsa",
    "description": "",
    "director": "Alena Shevchenko",
    "dop": "Daria Balanovskaya",
    "script": "Alena Shevchenko, Julia Frolova",
    "production": "TakeShot, FetishFilm, START",
    "ratio": 945/709,
    "misc": ""
}, 
{
    "code": "sial",
    "title": "Sorry I am Late, I was masturbating",
    "description": "This short film is a manifestation of masturbation. Female masturbation is still taboo in society, however, a man's masturbation is totally fine. A lot of women said they’d never masturbated. We have so many doubts about ourselves. The film should raise this topic, and make people more open to discuss it.",
    "director": "Alena Shevchenko",
    "dop": "Daniel Obradovic",
    "script": "",
    "production": "doity Produktion GmbH",
    "ratio": 1.776,
    "misc": {text: "Premiered on Nowness",
             link: "https://www.nowness.com/story/alena-shevchenko-sorry-i-am-late-i-was-masturbating"
        }
},
{
    "code": "BUMBLE",
    "title": "Bumble",
    "description": "",
    "director": "Alena Shevchenko",
    "dop": "Sara Dehlin",
    "script": "",
    "production": "STUDIO 11:40",
    "ratio": 945/709,
    "misc": {"text": "Creative Director: Kyra Nenz"}
},
{
    "code": "WOMANIZER",
    "title": "Womanizer",
    "description": "",
    "director": "Alena Shevchenko",
    "dop": "Sara Dehlin",
    "script": "",
    "production": ".fount",
    "ratio": 1290/729,
    "misc": ""
},
{
    "code": "Milky Chance",
    "title": "Living a Haze",
    "description": "",
    "director": "Alena Shevchenko",
    "dop": "Hee-Seong Han",
    "script": " Alena Shevchenko and Elnara Ialaltdinova",
    "production": "Stink Rising",
    "ratio": 1290/729,
    "misc": ""
}
]

export default data