import {React} from 'react';
import MainGrid from '../components/MainGrid';

const sial_cover ='https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers_1500/1500_sail.jpg'

const screens = ['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers_1500/1500_sail.jpg', // sial
                 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers/1500_bumble.jpg', // bumble
                 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/selected_1500/1500_website5.jpg', // anyway
                 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/selected_1500/1500_website1.jpg',
// 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/selected_1500/1500_website4.jpg',// honor
// 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/selected_1500/1500_website3.jpg', // nike
// 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/selected_1500/1500_website6.jpg',
// 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/selected_1500/1500_website7.jpg',
// 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/TOURISM_1000/1000_ПИТЕР.00_00_16_13.Still010.jpg'
]

const screensOptimized =['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers_1500/1500_sail.jpg',
    'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers/1500_bumble.jpg',
    'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/selected_1500webp/1500_website5.webp',
    'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/selected_1500webp/1500_website1.webp',

// 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/selected_1500webp/1500_website3.webp',
// 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/selected_1500webp/1500_website4.webp',

'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/selected_1500webp/1500_website6.webp',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/selected_1500webp/1500_website7.webp',
// 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/selected_1000webp/1000_%D0%9F%D0%98%D0%A2%D0%95%D0%A0.00_00_16_13.Still010.webp'
] 


const titles = ["",  "BUMBLE",  "Anyway", "I'll say it first",
//  "HONOR", "NIKE"
//   'TOURISM', "GUCCI", "CHARLIE PATTON",
]

function Selected() {
    // const r = useRef();
    // const [width, setWidth] = useState(0);

    // useEffect(() => {
    //     setWidth(r?.current?.clientWidth-(r?.current?.clientWidth/60))
    //   }, [r]);
    return(
        <MainGrid titles={titles} screens={screens} section="selected" screensOptimized={screensOptimized} />
    )
}
export default Selected