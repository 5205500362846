const today = new Date()
const premiere = new Date(1666328400248)
export const premiere_ready = today>premiere 

export const honor = ['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/HONOR_1500/1500_Picture1.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/HONOR_1500/1500_Picture2.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/HONOR_1500/1500_Picture3.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/HONOR_1500/1500_Picture4.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/HONOR_1500/1500_Picture5.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/HONOR_1500/1500_Picture6.jpg']

export const lime = ['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/lime_1500/1500_1.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/lime_1500/1500_10.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/lime_1500/1500_2.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/lime_1500/1500_3.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/lime_1500/1500_4.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/lime_1500/1500_5.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/lime_1500/1500_6.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/lime_1500/1500_7.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/lime_1500/1500_8.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/lime_1500/1500_9.jpg']

export const airwick = ['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/Airwick_1500/1500_Picture1.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/Airwick_1500/1500_Picture2.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/Airwick_1500/1500_Picture3.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/Airwick_1500/1500_Picture4.jpg']

export const anyway = ['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ANYWAY_1500/1500_Picture1.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ANYWAY_1500/1500_Picture2.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ANYWAY_1500/1500_Picture3.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ANYWAY_1500/1500_Picture4.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ANYWAY_1500/1500_Picture5.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ANYWAY_1500/1500_Picture6.jpg']

export const limeMen = ['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/lime_men_1500/1500_1.jpg',
  'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/lime_men_1500/1500_2.jpg',
  'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/lime_men_1500/1500_3.jpg',
  'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/lime_men_1500/1500_4.jpg']

export const NIKE = ['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/NIKE_1100/1100_Picture1.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/NIKE_1000/1000_Picture2.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/NIKE_1000/1000_Picture3.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/NIKE_1000/1000_Picture4.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/NIKE_1000/1000_Picture5.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/NIKE_1000/1000_Picture6.jpg']

export const polar = ['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/POLAR_1500/1500_Picture1.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/POLAR_1500/1500_Picture2.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/POLAR_1500/1500_Picture3.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/POLAR_1500/1500_Picture4.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/POLAR_1500/1500_Picture5.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/POLAR_1500/1500_Picture6.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/POLAR_1500/1500_Picture7.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/POLAR_1500/1500_Picture8.jpg']

export const first = ['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/first_1500/1500_Picture1.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/first_1500/1500_Picture2.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/first_1500/1500_Picture3.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/first_1500/1500_Picture4.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/first_1500/1500_Picture5.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/first_1500/1500_Picture6.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/first_1500/1500_Picture9.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/first_1500/1500_Picture7.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/first_1500/1500_Picture8.jpg',
]

export const encore = ['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/Encore_1500/1500_Picture1.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/Encore_1500/1500_Picture2.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/Encore_1500/1500_Picture3.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/Encore_1500/1500_Picture4.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/Encore_1500/1500_Picture5.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/Encore_1500/1500_Picture6.jpg']

export const blowup = ['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/blowup_1500/1500_Picture1.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/blowup_1500/1500_Picture2.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/blowup_1500/1500_Picture3.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/blowup_1500/1500_Picture4.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/blowup_1500/1500_Picture5.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/blowup_1500/1500_Picture6.jpg']


export const porter = ['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/porter_1500/1500_Picture1.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/porter_1500/1500_Picture2.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/porter_1500/1500_Picture3png.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/porter_1500/1500_Picture4.jpg']

export const portrait = 
 ['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/portrait_1500/1500_Picture1.jpg', 
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/portrait_1500/1500_Picture2.jpg', 
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/portrait_1500/1500_Picture3.jpg', 
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/portrait_1500/1500_Picture4.jpg', 
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/portrait_1500/1500_Picture5.jpg', 
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/portrait_1500/1500_Picture6.jpg', 
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/portrait_1500/1500_Picture7.jpg', 
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/portrait_1500/1500_Picture8.jpg']

 export const real = ['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/real_1500/1500_Picture1.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/real_1500/1500_Picture2.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/real_1500/1500_Picture3.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/real_1500/1500_Picture4.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/real_1500/1500_Picture5.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/real_1500/1500_Picture6.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/real_1500/1500_Picture7.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/real_1500/1500_Picture8.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/real_1500/1500_Picture9.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/real_1500/1500_Picture10.jpg',]

 export const mujuice =
['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/MUJUICE_1500/1500_Picture29.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/MUJUICE_1500/1500_Picture30.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/MUJUICE_1500/1500_Picture31.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/MUJUICE_1500/1500_Picture32.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/MUJUICE_1500/1500_Picture33.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/MUJUICE_1500/1500_Picture34.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/MUJUICE_1500/1500_Picture35.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/MUJUICE_1500/1500_Picture36.jpg']

export const zarnitsa =
['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ZARNITSA_1500/1500_Picture11.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ZARNITSA_1500/1500_Picture12.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ZARNITSA_1500/1500_Picture13.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ZARNITSA_1500/1500_Picture14.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ZARNITSA_1500/1500_Picture15.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ZARNITSA_1500/1500_Picture16.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ZARNITSA_1500/1500_Picture17.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ZARNITSA_1500/1500_Picture18.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ZARNITSA_1500/1500_Picture19.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ZARNITSA_1500/1500_Picture20.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ZARNITSA_1500/1500_Picture21.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ZARNITSA_1500/1500_Picture22.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ZARNITSA_1500/1500_Picture23.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ZARNITSA_1500/1500_Picture24.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ZARNITSA_1500/1500_Picture25.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ZARNITSA_1500/1500_Picture26.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ZARNITSA_1500/1500_Picture27.jpg',
 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ZARNITSA_1500/1500_Picture28.jpg']

export const TOURISM = [
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/TOURISM_1000/1000_ПИТЕР.00_00_45_12.Still008.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/TOURISM_1000/1000_ПИТЕР.00_00_33_23.Still003.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/TOURISM_1000/1000_ПИТЕР.00_00_33_00.Still002 (1).jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/TOURISM_1000/1000_ПИТЕР.00_00_18_00.Still004.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/TOURISM_1000/1000_ПИТЕР.00_00_46_03.Still009.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/TOURISM_1000/1000_ПИТЕР.00_00_31_00.Still007.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/TOURISM_1000/1000_ПИТЕР.00_00_21_05.Still005.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/TOURISM_1000/1000_ПИТЕР.00_00_16_13.Still010.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/TOURISM_1000/1000_ПИТЕР.00_00_23_02.Still006.jpg',]

export const mamaKoshka = ['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/mamakoshka_1500/1500_1.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/mamakoshka_1500/1500_2.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/mamakoshka_1500/1500_3.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/mamakoshka_1500/1500_4.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/mamakoshka_1500/1500_5.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/mamakoshka_1500/1500_6.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/mamakoshka_1500/1500_7.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/mamakoshka_1500/1500_8.jpg']

export const sial = premiere_ready? 
    ['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/sial_1500/1.jpg',
    'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/sial_1500/2.jpg',
    'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/sial_1500/3.jpg',
    'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/sial_1500/4.jpg',
    'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/sial_1500/5.jpg',
    'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/sial_1500/6.jpg',
    'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/sial_1500/7.jpg',
    'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/sial_1500/8.jpg',
    'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/sial_1500/9.jpg',
    'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/sial_1500/10.jpg',
    'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/sial_1500/11.jpg',
    'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/sial_1500/12.jpg',
    'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/sial_1500/1500_13.jpg',
    'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/sial_1500/14.jpg',
    'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/sial_1500/15.jpg',
    'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/sial_1500/1500_16.jpg'] 
    : []

export const adidas =['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ADIDAS_1500/1500_Picture+1.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ADIDAS_1500/1500_Picture+2.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ADIDAS_1500/1500_Picture+3.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ADIDAS_1500/1500_Picture+4.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ADIDAS_1500/1500_Picture+5.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/ADIDAS_1500/1500_Picture+6.jpg']

export const GUCCI =['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/GUCCI_1500/1500_1.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/GUCCI_1500/1500_2.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/GUCCI_1500/1500_3.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/GUCCI_1500/1500_4.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/GUCCI_1500/1500_5.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/GUCCI_1500/1500_6.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/GUCCI_1500/1500_7.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/GUCCI_1500/1500_8.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/GUCCI_1500/1500_9.jpg']

export const CHARLIE = [
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/CHARLIE_1500/1500_Shevchenko-LA-_1.18.1.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/CHARLIE_1500/1500_Снимок экрана 2022-09-05 в 13.53.53.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/CHARLIE_1500/1500_Снимок экрана 2022-09-05 в 13.54.10.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/CHARLIE_1500/1500_Shevchenko-LA-_1.16.1.jpg',]

export const WOMANIZER = ['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/womanizer_1500/1500_1.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/womanizer_1500/1500_2.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/womanizer_1500/1500_3.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/womanizer_1500/1500_4.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/womanizer_1500/1500_5.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/womanizer_1500/1500_6.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/womanizer_1500/1500_7.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/womanizer_1500/1500_8.jpg']

export const BUMBLE = ['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/bumble_1500/1500_1.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/bumble_1500/1500_10.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/bumble_1500/1500_2.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/bumble_1500/1500_3.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/bumble_1500/1500_4.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/bumble_1500/1500_5.jpeg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/bumble_1500/1500_6.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/bumble_1500/1500_7.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/bumble_1500/1500_8.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/bumble_1500/1500_9.jpg']