import {React} from 'react';
import * as screens from '../components/screens'
import MainGrid from '../components/MainGrid';

export const titles = ['mujuice', 'mamaKoshka', 'Milky Chance']
const screenshots = titles?.map(t => screens[t] ? screens[t][0] : '')
const covers = ['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers/mujuice.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers/mamakoshka.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers/livingahaze.jpeg'
]

function MusicVideo() {
    return(
        <MainGrid titles={titles} screens={screenshots} section="music-videos" covers={covers}/>
    )
}
export default MusicVideo