import {React} from 'react';
import MainGrid from '../components/MainGrid';
import * as screens from '../components/screens'

export const titles = ['BUMBLE', 'WOMANIZER',
'limeMen', 'lime','NIKE',  'honor', 'TOURISM', 
'encore',
// 'airwick', 
// 'adidas',
//  'blowup', 
//  'portrait', 'polar',
//  'porter'
]
const screenshots = titles?.map(t => screens[t][0])
const covers = ['https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers/1500_bumble.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers_1500/1500_womanizer.jpg', 
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers_1500/1500_lime_men.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/lime_1500/1500_10.jpg',
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/selected_1500/1500_website3.jpg', // nike
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/selected_1000/1000_website4.jpg', // honor
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/TOURISM_1000/1000_ПИТЕР.00_00_16_13.Still010.jpg', 
'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers/encore.jpg',
// 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers/airwick.jpg', 
// 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers/1500_adidas.jpg', 
// 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers/blowup.jpg', 
// 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers/portrait.jpg',
// 'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers/polar.jpg',
//  'https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/covers/porter.jpg',
]

function Commercials() {
    return(
        <MainGrid titles={titles} screens={screenshots} section="commercial" covers={covers}/>
    )
}
export default Commercials