
import React, { useRef, useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import ScreenshotGrid from './ScreenshotGrid';
import * as screens from './screens'
import { titles as commercialTitles} from '../sections/Commercials';
import { titles as musicVideoTitles} from '../sections/MusicVideo';
import { titles as narrativeTitles} from '../sections/Narrative';
import data from '../videoDescriptions';


function objFromEntries(titles) {
    return Object.fromEntries(titles.map((c, index) => [index+1, c]));
}

const names = {'first': "I'll say it first", 'real': "What's real", 'sial': ''}
const beregovoy = ['blowup', 'portrait', 'porter']

const findName = (title) => {
    if (names[title]) {
        return names[title]
    }
    if (beregovoy.indexOf(title) != -1) {
        return 'Beregovoy: ' + title
    } 
    return title[0]?.toUpperCase() + title.substring(1)
}

const today = new Date()
const premiere = new Date(1666328400248)
export const premiere_ready = today>premiere 
const sial_video = premiere_ready ? 'https://player.vimeo.com/video/746955982?h=cdd2211867' : ''

const videos = {
    'selected': [ sial_video,
               'https://player.vimeo.com/video/833959052?h=9e39a62964', // bumble
               'https://player.vimeo.com/video/291447549?h=b78a2d865f', // anyway
               'https://player.vimeo.com/video/385186106?h=09b86c3c92', // say it first
                // 'https://player.vimeo.com/video/671715064?h=95b6683ac4', // nike
                // 'https://player.vimeo.com/video/554679422?h=97f752db3d', // honor

                // 'https://player.vimeo.com/video/246930983?h=ec5c815e9c',
                // 'https://player.vimeo.com/video/337033022?h=4cc070a84c',
                // 'https://player.vimeo.com/video/478002559?h=3e4ee6db45'
                ],
    'commercial': [ 'https://player.vimeo.com/video/833959052?h=9e39a62964', // bumble
                    'https://player.vimeo.com/video/831933476', // womanizer
                   
                    'https://player.vimeo.com/video/1002690056', //lime men
                    'https://player.vimeo.com/video/906359480?h=1523876d02', // lime
                    'https://player.vimeo.com/video/671715064?h=95b6683ac4', // nike
                    'https://player.vimeo.com/video/554679422?h=97f752db3d', //honor
                    'https://player.vimeo.com/video/478002559?h=3e4ee6db45',
                    'https://player.vimeo.com/video/365017816?h=42057c57c6',
                    // 'https://player.vimeo.com/video/483637829?h=a24c282efd',
                    // "https://player.vimeo.com/video/288705282?h=878837d516",
                   
                    // 'https://player.vimeo.com/video/373560669?h=440315931d',
                    // 'https://player.vimeo.com/video/370047412?h=1efebf93ff',
                    // 'https://player.vimeo.com/video/705326338?h=88e4871635',  
                    // 'https://player.vimeo.com/video/370046296?h=8d723eb85a',
                ],
    'music-videos': ["https://player.vimeo.com/video/621733807?h=2df4a4ad35",
                     "https://player.vimeo.com/video/381228004?h=816f6ff4c4",
                     "https://player.vimeo.com/video/803237618?h=ce6438fbb7"
                    ],
    'films': [     sial_video,
                  'https://player.vimeo.com/video/385186106?h=09b86c3c92',
                  'https://player.vimeo.com/video/519762540?h=17c4f6f6f',
                  'https://player.vimeo.com/video/353755704?h=107ed9739b',
                  'https://player.vimeo.com/video/291447549?h=b78a2d865f'
                ],

}
const videoStills = {
    large: {
            selected: {
            1: 'sial',
            2: 'BUMBLE',
            3: 'anyway',
            4: 'first',
            // 3: 'NIKE',
                 // 4: 'honor',
            // 6: 'GUCCI',
            // 7: 'CHARLIE',
            // 8: 'TOURISM',
            },
            commercial: objFromEntries(commercialTitles),
            'music-videos': objFromEntries(musicVideoTitles),
            films: objFromEntries(narrativeTitles),

    },
}

const stills = {
    TOURISM: screens.TOURISM,
    first: screens.first,
    NIKE: screens.NIKE,
    honor: screens.honor,
    lime: screens.lime,
    limeMen: screens.limeMen,
    anyway: screens.anyway,
    encore: screens.encore,
    airwick: screens.airwick,
    portrait: screens.portrait,
    porter: screens.porter,
    polar: screens.polar,
    blowup: screens.blowup,
    real: screens.real,
    mujuice: screens.mujuice,
    zarnitsa: screens.zarnitsa,
    adidas: screens.adidas,
    GUCCI: screens.GUCCI,
    CHARLIE: screens.CHARLIE,
    mamaKoshka: screens.mamaKoshka,
    sial: screens.sial,
    BUMBLE: screens.BUMBLE,
    WOMANIZER: screens.WOMANIZER,
}
const verticalVid = 'https://player.vimeo.com/video/554679422?h=97f752db3d'

const videoDescription = (code) => {
    if (data.find((c) => c.code == code)) {
        const info = data.find((c) => c.code == code)
        return info
    }
}

function VideoPage() {
    const params = useParams()
    const ref = useRef(0)
    const ref1 = useRef(0)
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [descOffset, setDescOffset] = useState(0)
    const [realVideoWidth, setRealVideoWidth] = useState(0)
    const [description, setDescription] = useState({})
    const videoSrc = videos[params.type][params.videoId-1] + '?title=0&byline=0&portrait=0&sidedock=0'
    const vidName = videoStills['large'][params.type][params.videoId]
    useEffect(
        () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }, []
    )

    useEffect(() => window.scrollTo(0, 0), [])
    useEffect(() => {
        window.resizeTo(900, 1500)
    }, [])


    useEffect(() => {
        let offset = calculateOffset(window.innerWidth)
        if (offset > 45) {
            offset -= 40
        } else if (offset < 0) {
            offset = 0
        }
        setDescOffset(offset)
    }, [width])


    function debounce(fn, ms) {
        let timer
        return _ => {
            clearTimeout(timer)
            timer = setTimeout(_ => {
                timer = null
                fn.apply(this, arguments)
            }, ms)
        };
    }
      
    React.useEffect(() => {
        const debouncedHandleResize = debounce( function handleResize() {
          {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
            }
            let offset = calculateOffset(window.innerWidth)
            if (offset>45) {
                offset -= 40
            }
            else if (offset<0) {
                offset = 0
            }
            setDescOffset(offset)
        
    }, 800)
    
        window.addEventListener('resize', debouncedHandleResize)
    
        return _ => {
          window.removeEventListener('resize', debouncedHandleResize)
        
    }
      })

    React.useEffect(() => {
        setDescription(videoDescription(vidName))
    }, [vidName])

    const largeSceenGrid = {'airwick': true, 'real': true, 'honor': true, 'CHARLIE': true, "BUMBLE": true, "WOMANIZER": true, 'lime': true, 'limeMen': true}
    const ratio = width / (height+1)
    const videoHeight = ratio > 1? 750 : (1080*ratio)-300
    
    function calculateOffset(width) {
        const r = description?.ratio
        if (r) {
            if (videoHeight <= 800) {
                const realHeight = ref.current.clientHeight
                let heightAdjust = 1
                let realWidth = videoHeight * r 
                // console.log(realHeight)
                if (realHeight < 800 && ratio > 1) {
                     heightAdjust = 750/realHeight
                    //  console.log('adjusting height', heightAdjust)
                }
                realWidth = realWidth / heightAdjust
                setRealVideoWidth(realWidth)
                const offset = (width-realWidth)/2
                // console.log(realWidth, width, offset, r)
                return offset
            }
            else {
                return 0
            }
        }
        else{
            return 0
        }
    }

    let largeStills = stills[vidName]
    useEffect(() =>{
        const name = findName(vidName)
        if (name) {
            document.title = name
        }},[vidName])

    useEffect(() =>{
        document.description = params.type != 'selected' ?
        findName(vidName) + '— a ' + params.type[0]?.toUpperCase() + params.type.substring(1) + ' by Alena Shevchenko'
         : findName(vidName) +' selected artwork by Alena Shevchenko'
     }, [vidName])

    return(
        <React.Fragment> 
            <div className='video-holder' ref={ref1}>
                {
                // videos[params.type][params.videoId-1] ?
                    <iframe id="vidframe" src={videoSrc} ref={ref}
                    frameBorder="0" 
                    width="1920" height={(videoSrc != verticalVid)? videoHeight: videoHeight+350}
                    allow="autoplay; fullscreen; picture-in-picture" 
                    webkitallowfullscreen="" mozallowfullscreen="" 
                    allowFullScreen="" data-ready="true"
                    ></iframe>

                
                    // :  <div className='video-holder'>
                    //     <img  className='preview-ss' src='https://alenaportfolio.s3.eu-central-1.amazonaws.com/Projects/1500_sial.jpg'></img>
                    //     <p >Premiere soon </p>
                    // </div>
                    }
            </div>
            {description ? 
                <div className='video-description' style={{marginLeft: descOffset}}>
                    <React.Fragment>
                        <b className='descr'>  {description.title} </b> 
                        <p className='descr'> Production: {description.production} </p>
                        <p className='descr'> Director of photography: {description.dop} </p>
                        {description.script? <p className='descr'> Script: {description.script}</p> : ''}
                        {vidName != "sial"  ? 
                         description?.misc?
                            description?.misc.link? 
                                <a href={description?.misc?.link} target='_blank' rel="noreferrer"> {description.misc?.text} </a> :
                                <p className='descr'>  {description.misc.text} </p> 
                                : '' : ''}
                        {vidName != "sial" ?
                        <p className='descr' style={realVideoWidth? {maxWidth: realVideoWidth} : {}}>  {description.description} </p> : ''}

                        {/* <p className='descr'> Director: {description.director} </p> */}
                        
                      {vidName == "sial" ? 
                        <React.Fragment>
                            <br></br>
                            <p className='descr' style={realVideoWidth? {maxWidth: realVideoWidth} : {}}>  {description.description} </p>
                            
                            <br></br>
                            {description.misc? 
                                description.misc.link? 
                                    <a href={description.misc.link} target='_blank' rel="noreferrer"> {description.misc.text} </a> :
                                    <p className='descr'>  {description.misc.text} </p>
                            :'' }
                        </React.Fragment> 
                        :
                       ''
                    }
                       
                    </React.Fragment>

                   
            </div> 
            : null 
                }
            <div className='video-holder mt-3'>
                <ScreenshotGrid largeStills={largeStills} largeGrid={largeSceenGrid[vidName]}/>
            </div>

            
         </React.Fragment>
    )
}

export default VideoPage