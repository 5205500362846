import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Link,
  Route, } from "react-router-dom";
import VideoPage from './components/VideoPage';
import Selected from './sections/Selected';
import Commercials from './sections/Commercials';
import MusicVideo from './sections/MusicVideo';
import Narrative from './sections/Narrative';
import About from './sections/About';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  
    <BrowserRouter>
    <App/>
      <Routes>
        <Route exact path='/' element={<Selected />}> </Route>
          <Route path="portfolio/:type/:videoId" element={<VideoPage />}> 
         
        </Route>
        <Route path="portfolio/films/" element={<Narrative/>} /> 
        <Route path="portfolio/commercial/" element={<Commercials/>} /> 
        <Route path="portfolio/music-videos/" element={<MusicVideo/>} /> 
        <Route path="about" element={<About/>} /> 
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
